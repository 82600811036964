<template>
  <el-dialog
      title="官方模板"
      :visible.sync="dialogVisible"
      width="1112px"
  >
  <Pay ref="pay" :message1="checkedGift"/>
  
  
  <EditsInfo ref="EditsInfo" :info="info"/>
  
    <div class="gifts clearFix"> 
	<div style="width: 80%; display:block; float: left;">
		
		<div  v-for="(item, i) in temps"   :key="i" :span="41" style="width: 100%!important;"><label>
		
		<input name="Fruit" type="checkbox"   v-model="contents"   :value="item"  />{{item}} </label> </div>
		
	</div>  
			  
		<div style="width: 20%; display:block;float: right;">
			
			<button>删除</button><br/><br/>
			<button>赋值</button><br/><br/>
			<button>修改</button><br/> <br/>
			<button>保存</button><br/><br/>
			<button @click="goSet(2)" >使用</button><br/> <br/>
			<button @click="add(2)" >1添加</button><br/> <br/>
		</div>
		  
  <!--    <el-pagination
          small
          layout="prev, pager, next"
          :page-count="gifts.length"
          :current-page.sync="index"
          hide-on-single-page
      /> -->
    </div>
    <!-- <div class="info">
      <div>赠送：<span>{{info.name}}</span>(ID: {{info.id}}, {{info.sex}}, {{info.age}}, {{info.major}})</div>
      <div>礼物：
        <span style="margin-right: 20px">{{checkedGift.name}}</span>
        <el-input-number v-model="num" :min="1" :max="10" size="mini"/>
      </div>
      <div>祝福语：
        <el-input
            type="textarea"
            :rows="2"
            placeholder="输入祝福语"
            v-model="textarea"
            style="margin-top: 10px"
        />
      </div> -->
    </div>
<!--    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSend3">赠送</el-button>
    </span> -->
  </el-dialog>
  
  
</template>

<script>
	
	import axios from "axios";
	import data from '@/data/data.js' 
    import Pay from './Pay.vue'
    import EditsInfo from '@/components/EditsInfo'
	export default {
    name: "AddCaseInfo",
    components: { Pay ,EditsInfo},
    props: ['info'],
    data() {
      return {
		   messageToHelloWorld:'我是父组件向下传递的信息',
		  id:0,
        dialogVisible: false,
        gifts: [],
        index: 0,
        checkedGift:{
          id: 60,
          uid: 60,
          name: '玫瑰花',
          url: require('../assets/gifts/5.jpg'),
		  bid:0,
		  num:0,
		  message:'',
		  
        },
		finfo:{},
        num: 1,
        textarea: '',
		temps:{},
		contents:[],
		case_id:0,
      };
    },
	created(){ 
		const params1 = new URLSearchParams();
		params1.append('id', this.case_id); 
		//alert(this.case_id);
	 
		axios.post(this.apiUrl+'/api/home/getCaseTemp', params1)
		.then((response) => { 	
					   
				this.temps =  response.data.data;
				 
		  })
		  .catch(function(error) {
		    console.log(error);
		  });
				
	},
	
	 watch: {
	      info: function(newVal,oldVal){
	        this.finfo = newVal; //newVal即是chartData
	        this.drawChart();
	      }
	    },
    mounted() {
      //this.formatGifts(data.gifts)
	  
	  //礼物列表
	   

	  
	  
    },
    methods: {
		add(){
			
			
			this.$refs.EditsInfo.open(this.case_id);
				
			//this.temps.push("asdfas")
		},
		goSet(){ 
			
			console.log(this.contents);
			var str = "";
			for(var j = 0,len=this.contents.length; j < len; j++) {
				str += this.contents[j]+"  ";
			}
			this.$parent.textarea = str;
			this.dialogVisible = false;
			 
		},
		
		
		
		gohandleSend2() {
			//alert(3);
			},
			
			
      open(id) {
		  this.case_id = id;
        this.dialogVisible = true
      },
	   handleSend3() { 
		   this.checkedGift.uid = sessionStorage.getItem('uid');
		   this.checkedGift.bid = this.finfo.id;
		   this.checkedGift.num = this.num;
		   this.checkedGift.message = this.textarea; 
 
		   
		   
		   this.$refs.pay.open()
	   },
      handleSend2() {
        const that = this
        this.$confirm('确认赠送？')
          .then(() => {
			  console.log("++++++++")
			  console.log(this.checkedGift)
             
			 
			 const params3 = new URLSearchParams();
             			  
             params3.append('uid', sessionStorage.getItem('uid')); 
	 
			 params3.append('bid', this.id); 
	 
			 params3.append('name', this.checkedGift.name); 
		 
			 params3.append('url', this.checkedGift.url); 
	 
			 params3.append('num', this.num); 
	 
			 params3.append('message', this.textarea); 
		 
		 
		 console.log(params3);
             
             axios.post(this.apiUrl+'/api/user/giveGift', params3)
             .then((response) => { 	
             			   
             		this.gifts =  response.data.data;
             		 
               })
               .catch(function(error) {
                 console.log(error);
               });
			   
			   
			   
          })
          .catch(() => {});
      },
      handleSend() {
        const that = this
        this.$confirm('确认赠送？')
          .then(() => {
            that.dialogVisible = false
          })
          .catch(() => {});
      },
      formatGifts(data) {
        let num = Math.ceil(data.length / 12)
        this.gifts = new Array(num)
        for (let i = 0; i < num; i++) {
          this.gifts[i] = data.slice(i*12, i*12+12);
        }
      },
      check(item){
        this.checkedGift = item
      }
    }
  }
</script>

<style scoped lang="scss">
  .gifts{
    .title{
      margin-bottom: 21px;
      color: #333;
    }
    .gift{
      width: 84px;
      height: 84px;
    }
    .el-icon-success{
      position: absolute;
      bottom: 5px;
      right: 50%;
      transform: translateX(42px);
      font-size: 17px;
      color: #FF2D2D;
    }
    .el-pagination{
      text-align: center;
    }
  }
  .info{
    &>div {
      margin-bottom: 10px;
    }
  }
  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 18px 16px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
  /deep/ .el-pager li:last-child{
    border-color: $green
  }
  .el-col-4{
    text-align: center;
    position: relative;
    margin-bottom: 16px;
  }
  .el-input-number--mini{
    width: 74px;
    line-height: 22px;
  }
  /*/deep/ .el-input__inner{*/
  /*  border: unset;*/
  /*}*/
  .el-input-number__decrease, .el-input-number__increase{
    background-color: transparent;
  }
  /deep/ .el-input--mini .el-input__inner{
    padding-left: 0;
    padding-right: 0;
}
  /deep/ .el-input--mini .el-input__inner{
    height: 24px;
  }
  /deep/ .el-input-number--mini .el-input-number__decrease, /deep/ .el-input-number--mini .el-input-number__increase{
    width: 20px;
  }
</style>
