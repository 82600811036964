<template>
	<my-dialog :visible="visible" title="预约设置" @cancel="close" footer width="1300px" 
	style="margin-top:3px!important;">
  <input class="time" type="text" value="14:30" onchange="console.log('Time changed')" />
<!-- 
	<el-time-picker style="width: 120px;" value-format="HH:mm" format="HH:mm"
													v-model="arsenal" :picker-options="{
																		  selectableRange: '08:30:00 - 12:00:00'
																		}" placeholder="">
													</el-time-picker> -->
<!-- 
<el-time-select
  v-model="value2"
  :picker-options="{
    start: '08:30',
    step: '00:15',
    end: '18:30'
  }"
  placeholder="选择时间">
</el-time-select><el-time-select
  v-model="value3"
  :picker-options="{
    start: '08:30',
    step: '00:15',
    end: '18:30'
  }"
  placeholder="选择时间">
</el-time-select><el-time-select
  v-model="value4"
  :picker-options="{
    start: '08:30',
    step: '00:15',
    end: '18:30'
  }"
  placeholder="选择时间">
</el-time-select> -->
<!-- 
<input type="date" > -->

							 <el-button type="primary" @click="onSubmit">立即创建</el-button>

	<el-tabs v-model="tabActive">

		 <el-tab-pane label="本周" name="first">
			<el-form ref="form" :model="form" label-width="200px" style="height: 450px; ">
			 
						<div class="tableBox" style="height: 450px;">
							
							
							<el-table :data="froms.tableDataNow" height="450" border style="width: 100%">

							 

								<el-table-column prop="date" label="日期" width="100">
									<template slot="header">
										<div style="display: flex; justify-content: space-between">
											<span>日期</span> <span>时间</span>
										</div>
									</template>
									<template slot-scope="scope">
										<div slot="reference" style="text-align: center;">
											{{ scope.row.week_name }}
											<br />
											{{ scope.row.week_time }}
										</div>
									</template>
								</el-table-column>


								<el-table-column prop="name" style="text-align: center;" label="上午2">
									<template slot-scope="scope">
										<div slot="reference" style="text-align: center;">
											 
											<div class="news left" v-for="(item,i)  in  scope.row.date_v_model"  >
												<span style="float: left;width: 130px;"> 
												   {{scope.row.date_v_model[i]}}
												   
												   
													<el-time-picker style="width: 120px;" value-format="HH:mm" format="HH:mm"
													v-model="scope.row.date_v_model[i]" :picker-options="{
																		  selectableRange: '08:30:00 - 12:00:00'
																		}" placeholder="">
													</el-time-picker>   
													
													 
											 

												</span>
											</div>

										</div>
										
									</template>
									


								</el-table-column>
								


								<el-table-column prop="address" label="下午">
									

									<template slot-scope="scope">
										
										<!-- <div slot="reference" style="text-align: center;">

											<div class="news left" v-for="(item,index) in  4">
												<span style="float: left;width: 130px;">


													<el-time-picker style="width: 120px;" v-model="times" value-format="HH:mm"
														format="HH:mm" :picker-options="{
																		  selectableRange: '12:30 - 20:00'
																		}" placeholder="">
													</el-time-picker>&nbsp;

												</span>
											</div>

										</div> -->
									</template>
									

								</el-table-column>
								



							</el-table>
						</div>
						<div class="line" style=" background-color: #EBEEF5; margin-top: 7%; margin-left: 2%;"
							:style="{width:lineWhide+'px',transform:'rotate(21deg)'}">
							</div>
							
							 
			</el-form>
		</el-tab-pane>
 
		<el-tab-pane label="下周" name=" ">
			<el-form ref="form" :model="form" label-width="200px" style="height: 450px; "> 
						<div class="tableBox" style="height: 450px;">
							<el-table :data="froms.tableDataNext" height="450" border style="width: 100%">  
								<el-table-column prop="date" label="日期" width="100">
									<template slot="header">
										<div style="display: flex; justify-content: space-between">
											<span>1日期</span> <span>时间</span>
										</div>
									</template>
									<template slot-scope="scope">
										<div slot="reference" style="text-align: center;">
										{{ scope.row.week_name }}
										<br />
										{{ scope.row.week_time }}
										</div>
									</template>


								</el-table-column>


								<el-table-column prop="name" style="text-align: center;" label="上午">
									<template slot-scope="scope">
										<div slot="reference" style="text-align: center;">

											<div class="news left" v-for="(item,index) in  date_v_model">
												<span style="float: left;width: 130px;">


													<el-time-picker 
														style="width: 120px;" 
														value-format="HH:mm" 
														format="HH:mm"
														v-model="scope.row.date_v_model['keys'+index]" 
														:picker-options="{
																		  selectableRange: '08:30:00 - 12:00:00'
																		}" placeholder="">
													</el-time-picker>&nbsp;

												</span>
											</div>

										</div>
									</template>


								</el-table-column>


								<el-table-column prop="address" label="下午">

									<template slot-scope="scope">
										<div slot="reference" style="text-align: center;">

											<div class="news left" v-for="(item,index) in  4">
												<span style="float: left;width: 130px;">


													<el-time-picker style="width: 120px;" v-model="times" value-format="HH:mm"
														format="HH:mm" :picker-options="{
																		  selectableRange: '12:30 - 20:00'
																		}" placeholder="">
													</el-time-picker>&nbsp; 
												</span>
											</div> 
										</div>
									</template> 
								</el-table-column> 
							</el-table>
						</div>
						
						
						<div class="line" style=" background-color: #EBEEF5; margin-top: 7%; margin-left: 2%;"
							:style="{width:lineWhide+'px',transform:'rotate(21deg)'}">
						</div>
			</el-form>
		</el-tab-pane>

	</el-tabs> 


	</my-dialog>
</template>

<script>
	
	import $ from "jquery"
  import axios from "axios"; 
	import MyDialog from "../../../../components/MyDialog";

	export default {
		name: 'SetTtreatmentTimeDialog',
		components: {
			MyDialog
		},
		data() {
			return {
				arsenal:'',
				timeaab:'',
				timeaac:'',
				timeaad:'',
				
				 value: '',
				 value2: '',
				 value3: '',
				 value4: '',
				tabActive: 'first',
				times: '',
				visible: false,
				froms:{
					tableDataNow: [],
					tableDataNext: [],
				},
				obj:{},
			
			}
		}, 
		created(){  
	
			
			const params = new URLSearchParams(); 
			params.append('uid', sessionStorage.getItem('uid')); 
			console.log(params);
			axios.post(this.apiUrl+'/api/doctor/getVisitDate',params)
			.then((response) => {  
				console.log(response.data.data);
				this.froms.tableDataNow = response.data.data.nowDate; 
				this.froms.tableDataNext = response.data.data.nextDate;  
			}) 
			.catch(function(error) {
			  console.log(error);
			}); 
			
	 
			
		}, 
		mounted:function(){
		            this.$nextTick(function(){
		                   $('.time').clockTimePicker({});
		            })
		        },
		
		methods: {
			 onSubmit() { 
					
					// console.log(this.from);
				 //  alert(this.arsenal);
				 // // alert(this.froms.tableDataNow[0].date_v_model.keys0);
				 // console.log(JSON.stringify(this.from))
			
			const params3 = new URLSearchParams();
			
			params3.append('uid', this.did);
			params3.append('uuid', this.froms.tableDataNow); 
			 
			axios.post(this.apiUrl+'/api/doctor/setVisitDate',params3)
			.then((response) => {   
				    
				
			}) 
			.catch(function(error) {
			  console.log(error);
			});
				 
				 
				 console.log("////////////////");
				 console.log(this.froms.tableDataNow[0]);
				 const formData = new FormData()
				 //这里是把上传接口所有的参数都append到formData中
				 formData.append('sss', this.froms.tableDataNext)
				 const config = {
				     headers: {
				         'Content-Type': 'multipart/form-data'
				     }
				 } 
				  
				 axios.post(this.apiUrl+'/api/doctor/setVisitDate', formData, config).then((res) => { 
				 	this.homeCities = res.data.data; 
				 })
				 
				 
					 
			      },
				show() {
					this.visible = true;
				},

				close() {
					this.visible = false;
				}
		}
	}
</script>


<style scoped lang="scss">
	.tableBox {
		margin-top: 50px;
		position: relative;
		/* background-color: red; */
	}

	.line {
		position: absolute;
		/* position:fixed; */
		z-index: 9;
		top: 0;
		left: 0;
		height: 1px;
		width: 110px;
		background-color: black;
		transform-origin: left;
		transform: rotate(24.2deg);
	}

	.el-slider {
		margin-top: 40px;
	}

	/deep/ .el-slider__marks-text {
		font-size: 12px;
		width: 44px;
	}

	.tips {
		color: $red;
		text-align: right;
		font-size: 12px;
		margin-top: 20px;
	}

	.text {
		margin: 40px 20px;
		font-weight: 500;
	}

	.button {
		text-align: center;

		.el-button {
			min-width: 120px;
		}
	}

	.content {
		height: 100px;
		margin: 20px;
		border: 2px solid $red;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background: #FEF6F4;
		color: $red;
		padding: 20px;

	}
</style>
