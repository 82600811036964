<template>
  <el-card class="mine-service">
    <div slot="header">
      <h3>22医疗服务</h3>
    </div>
	
    <div>
			<ul style="padding: 0!important;">
				
				<li @click="addPatient(1)" >
					<img  src="../../../assets/images/m_case.png">
					<p>(空白模板)</p>
				</li>  
				
			</ul>
    </div>
	
	
	  
	<CaseInfo ref="CaseInfo" :info="info"/>
	
  </el-card>
</template>kl

<script>
  import axios from "axios"; 
  
  import CaseInfo from '@/components/AddCaseInfo'
   
  import SetVisitorDialog from "./components/SetVisitorDialog";
  import SetDurationDialog from "./components/SetDurationDialog";
  import SetTtreatmentTimeDialog from "./components/SetTtreatmentTimeDialog";
  import {setOnSpot} from "../../../api/DoctorApi";
  export default {
    name: "ServiceIndex",
    components: {SetDurationDialog, SetVisitorDialog,SetTtreatmentTimeDialog,CaseInfo},
    data(){
      return {
		list:{},
		info:4
      }
    },
	
	
	created(){
		
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem('uid')); 
	 
		axios.post(this.apiUrl+'/api/CaseTemplate/getAdminTemplates',params)
		.then((response) => {   
			
			this.list = response.data.data;  
			
		}) 
		.catch(function(error) {
		  console.log(error);
		});
	},

    methods:{
	 /**
	 * 看病预约设置
	 */
	setTtreatmentTime(){
	 
		this.$refs['setTtreatmentTime'].show();
	},

      /**
       * 增加咨询病人
       */
      addPatient(){ 
		  // this.$refs['addPatient'].ids = 2;
    //     this.$refs['addPatient'].show(2);
		
		  this.$refs.CaseInfo.open() 
      },

      /**
       * 设置咨询者人数
       */
      setVisitor(){
        this.$refs['setVisitor'].show();
      },

      /**
       * 即诊设置
       */
      onSpot(){
        this.$confirm('确认要开始即诊?', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          setOnSpot().then(() => {
            this.$message.success("设置成功，两小时后请再次确认");
          })
        }).catch(() => {});
      },

      /**
       * 设置每个病人每次预约时间
       */
      setDuration(){
        this.$refs['setDuration'].show();
		
      }
    }
  }
</script>

<style scoped lang="scss">
	.imgs li img{
		width: 60px;
	}
	li{
	            list-style-type:none;
	            width:20%;
				height: 20%;
				//background-color: rosybrown;
				float: left;
				text-align: center;
	} 
	
	li img{
		width: 35px;
	}
  .el-card {
    min-height: 1050px;

    h3 {
      margin: 0px;
      padding: 0px;
    }

    .el-button {
      margin: 20px 40px;
      padding: 10px 20px;
      min-width: 100px;
    }
  }


</style>
